<script setup>
import SocialButton from '@/Components/SocialButton.vue';
import { router } from '@inertiajs/vue3';
import { toRefs } from 'vue';

import {
    signInWithPopup,
    signOut,
    GoogleAuthProvider,
    OAuthProvider
} from 'firebase/auth';
import { useFirebaseAuth } from 'vuefire';

import { locale } from '@/Localization.js';
import { trans, transChoice } from 'laravel-vue-i18n';

const props = defineProps({
    subscription: { type: Object },
});

const { subscription } = toRefs(props);

const auth = useFirebaseAuth() // only exists on client side
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

const handleSignOut = () => {
    signOut(auth).then(() => {
        router.post(route('logout'));
    });
};

const handleSignIn = (provider) => {
    if (auth.currentUser) {
        handleSignOut();
    }

    signInWithPopup(auth, provider)
        .then((result) => {
            // var location = route('auth.profile');
            var location = route('customer.app.index', { 'locale': locale.value });
            if (route().current().startsWith('auth.')) {
                // router.get(location);
                window.location.href = location;
            }
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage);
            if (errorCode == 'auth/operation-not-allowed') {
                alert('Opération non permise.');
            }
        });
};

const handleSignInWithGoogle = () => handleSignIn(googleProvider);
const handleSignInWithApple = () => handleSignIn(appleProvider);

</script>
<template>
    <div class="text-gray-900 dark:text-white">

        <div class="flex gap-4 flex-col sm:flex-row justify-center items-center mt-2 text-center lg:max-w-full mx-auto">
            <SocialButton @click="handleSignInWithGoogle()">
                <!-- Google -->
                <svg class="scale-90 h-6 w-6 text-black dark:text-white fill-[#CD3C14]" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 488 512" fill="currentColor">
                    <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                </svg>
                <span>{{ trans('Se connecter avec Google') }}</span>
            </SocialButton>
            <!-- </div>
        <div class="mt-2"> -->
            <SocialButton @click="handleSignInWithApple()">
                <!-- Apple -->
                <svg class="h-6 w-6 text-black dark:text-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" fill="currentColor">
                    <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                </svg>
                <span>{{ trans('Se connecter avec Apple') }}</span>
            </SocialButton>
        </div>
    </div>
</template>